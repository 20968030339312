/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. */
import 'es6-shim';

// removed because version 2 of core-js has change the package layout
// and Angular 8 finds its own polyfills for these

// import 'core-js/fn/object/assign'
// import 'core-js/fn/string/ends-with'
// import 'core-js/fn/string/starts-with'
// import 'core-js/fn/string/includes'
// import 'core-js/fn/array/includes'

// import 'core-js/actual/array/from';       // <- at the top of your entry point
// import 'core-js/actual/array/group';      // <- at the top of your entry point
// import 'core-js/actual/set';              // <- at the top of your entry point
// import 'core-js/actual/promise';          // <- at the top of your entry point
// import 'core-js/actual/structured-clone'; // <- at the top of your entry point
// import 'core-js/actual/queue-microtask';

import 'url-search-params-polyfill'; // removed because the new Angular HttpClient uses HttpParams instead

import 'custom-event-polyfill';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */

import 'classlist.js'; // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following to support `@angular/animation`. */
import 'web-animations-js'; // Run `npm install --save web-animations-js`.

// removed because version 2 of core-js has change the package layout
// and Angular 8 finds its own polyfills for these

/** Evergreen browsers require these. */
// import 'core-js/es6/reflect';
// import 'core-js/es7/reflect';

/** ALL Firefox browsers require the following to support `@angular/animation`. */
import 'web-animations-js'; // Run `npm install --save web-animations-js`.

/** Fix Safari because its dumb as fuck */
import 'web-animations-js/web-animations.min';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */

import 'zone.js/dist/zone'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */

(window as any)['global'] = window;
import 'intl';
import 'intl/locale-data/jsonp/en';
